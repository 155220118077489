import React from 'react';
import NoPageIcon from './../../Assets/Images/NoPage/NoPage.png';
import { Link } from 'react-router-dom';

const NoPage = () => {
    return (
        <>
            <main>
                <section className='py-[7.5rem] flex justify-center items-center flex-col'>
                    <div>
                        <img src={NoPageIcon} />
                    </div>

                    <div className='mb-[1rem]'>
                        <h3 className='text-[32px] text-[#272727] font-[700]'>صفحه پیدا نشد</h3>
                    </div>

                    <div>
                        <p className='text-[#3F3F3F] text-[20px] font-[400]'>صفحه‌ای که به دنبال آن هستید وجود ندارد. به صفحه‌ی اصلی برگردید.</p>
                    </div>

                    <div className='mt-[2rem]'>
                        <Link to={'/'} className='flex justify-center items-center w-max bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                            <p>بازگشت به خانه</p>

                            <svg className='mr-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4142 12.0001L14.7071 16.293L13.2929 17.7072L8.29289 12.7072C7.90237 12.3167 7.90237 11.6835 8.29289 11.293L13.2929 6.29297L14.7071 7.70718L10.4142 12.0001Z" fill="white"/>
                            </svg>
                        </Link>
                    </div>
                </section>
            </main>
        </>
    );
};

export default NoPage;