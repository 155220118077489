import React from 'react';
import Game from './../../Assets/Images/Shop/game.png';
import headphones from './../../Assets/Images/Shop/headphones.png';
import mobile from './../../Assets/Images/Shop/mobile.png';
import monitor from './../../Assets/Images/Shop/monitor.png';
import photo from './../../Assets/Images/Shop/photo.png';
import sport from './../../Assets/Images/Shop/sport.png';
import { Link } from 'react-router-dom';

const Category = ({data}) => {
    return (
        <>
            <section className='flex justify-center items-center'>
                <h3 className='text-[#000000] text-[26px] font-[700]'>دسته‌بندی‌ها</h3>
            </section>

            <section className='my-[2rem] px-[2rem] grid grid-cols-3 lg:grid-cols-6 gap-4 lg:gap-8'>
                {data?.items && Array.isArray(data?.items) ? (
                    data?.items.map((data,index) => (
                        <Link to={`/search?category=${data?.id}`} className='transition-[0.3s] item-category-shop hover:scale-[1.06] bg-[#F9F9F9] rounded-[0.5rem] flex justify-center items-center flex-col p-[1rem]'>
                            <img src={data?.image} />

                            <p className='text-[#424750] text-[12px] lg:text-[18px] text-center font-[600] mt-[0.5rem]'>{data?.title}</p>
                        </Link>
                    ))
                ) : (
                    <></>
                )}
            </section>
        </>
    );
};

export default Category;