import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import useAuthRedirect from '../../Logics/useAuthRedirect';
import { useCookies } from 'react-cookie';
import ProductBox from '../../Components/Dashbord/ProductBox';
import Select from 'react-select';
import instruments from './../../Assets/Jsons/instruments.json';
import { useSelector } from 'react-redux';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '3rem',
        width: '100%',
        backgroundColor: '#F5F5F5',
        borderColor: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: 'none',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
};

const Dashboard = () => {
    const [cookies] = useCookies(['authToken']);
    const [loading, setLoading] = useState(false);
    var token = cookies.authToken;
    const user = useSelector((state) => state.user);
    
    const [errors, setErrors] = useState({});
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [saz, setSaz] = useState('');
    const [sazI, setSazI] = useState('');
    const [optionsSaz, setOptionsSaz] = useState([]);

    const [profileImage, setProfileImage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [File, setFile] = useState(null);

    const handleProfilePicChange = (e) => {
        const file = e.target.files[0];
        setFile(e.target.files[0]);

        if (file) {
            setSelectedFile(file);
            setProfileImage(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        const formattedOptions = Object.keys(instruments).map((key) => ({
            value: key,
            label: `${instruments[key]}`,
        }));
        setOptionsSaz(formattedOptions);
    }, []);

    const validatePayment = () => {
        let formErrors = {};
        
        // if (!fullName) formErrors.fullName = 'نام و نام خانوادگی اجباری است';
        // if (!phone) formErrors.phone = 'شماره تماس اجباری است';
        // if (!email) formErrors.email = 'آدرس ایمیل اجباری است';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handelSubmitProfile = (e) => {
        e.preventDefault();
        if (validatePayment()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);
            

            const formdata = new FormData();
            if (sazI) {
                formdata.append("instrument", sazI);
            }

            formdata.append("name", fullName);
            formdata.append("email", email);

            if (File) {
                formdata.append("avatar", File);
            }

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(`https://backend.neireez.com/v1/profile?_method=PATCH`, requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status === 200 || status === 201) {
                    toast.success('پروفایل با موفقیت ذخیره شد .',{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }else{
                    let formErrors = {};

                    if (result?.errors?.email) {
                        formErrors.email = result?.errors?.state_id;
                    }

                    
                    if (result?.errors?.name) {
                        formErrors.fullName = result?.errors?.name;
                    }

                    
                    if (result?.errors?.instrument) {
                        formErrors.saz = result?.errors?.instrument;
                    }

                    
                    if (result?.errors?.avatar) {
                        formErrors.code = result?.errors?.postal_code;
                    }
                    
                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }

                    setErrors(formErrors);
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
        }
    }

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await fetch('https://backend.neireez.com/v1/locations/states');
                const data = await response.json();
                const provinceOptions = data?.data.map(province => ({
                    value: province.id,
                    label: province.name
                }));

                setProvinces(provinceOptions);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces();
    }, []);

    const handleSazChange = (selectedOption) => {
        setSazI(selectedOption.value);
    };

    useEffect(() => {
        setFullName(user?.profile?.data?.name);
        setPhone(user?.profile?.data?.phone);
        setEmail(user?.profile?.data?.email);
        setProfileImage(user?.profile?.data?.avatar?.original);
        setSaz({
            value: user?.profile?.data?.instrument,
            label:user?.profile?.data?.instrument
        });
    }, [user])
    
    
    return (
        <>
            <section className='w-full lg:border border-[#F0F0F0] rounded-[0.5rem] mt-[1rem] lg:mt-0 lg:mr-[3rem] lg:px-[2rem] lg:py-[1rem]'>
                <div className='flex justify-between items-center'>
                    <h3 className='text-[17px] lg:text-[24px] text-[#0C0C0C] font-[600] ml-[0.5rem]'>ویرایش پروفایل</h3>
                </div>

                <form onSubmit={handelSubmitProfile} encType='multipart/form-data'>
                    <div className='w-full'>
                        <div className="grid gap-6 lg:grid-cols-2 lg:gap-8 mt-[1.5rem]">
                            <div className="relative w-[6rem] h-[6rem] mb-[1rem] flex justify-center items-center">
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />

                                <input
                                    type="file"
                                    id="profile-pic-upload"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                    onChange={handleProfilePicChange}
                                />

                                <button
                                    type="button"
                                    className="absolute bottom-[-0.5rem] left-[-0.5rem] bg-white rounded-full p-2"
                                    onClick={() => document.getElementById('profile-pic-upload').click()}
                                >
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.492 0.0364C9.906 0.0364 10.242 0.3724 10.242 0.7864C10.242 1.2004 9.906 1.5364 9.492 1.5364H5.753C3.169 1.5364 1.5 3.3064 1.5 6.0454V14.3594C1.5 17.0984 3.169 18.8684 5.753 18.8684H14.577C17.161 18.8684 18.831 17.0984 18.831 14.3594V10.3314C18.831 9.9174 19.167 9.5814 19.581 9.5814C19.995 9.5814 20.331 9.9174 20.331 10.3314V14.3594C20.331 17.9534 18.018 20.3684 14.577 20.3684H5.753C2.312 20.3684 0 17.9534 0 14.3594V6.0454C0 2.4514 2.312 0.0364 5.753 0.0364H9.492ZM18.2016 0.915L19.4186 2.132C20.0116 2.724 20.3376 3.511 20.3366 4.349C20.3366 5.187 20.0106 5.973 19.4186 6.564L11.9096 14.073C11.3586 14.624 10.6246 14.928 9.8446 14.928H6.0986C5.8966 14.928 5.7026 14.846 5.5616 14.701C5.4206 14.557 5.3436 14.362 5.3486 14.159L5.4426 10.38C5.4616 9.628 5.7646 8.921 6.2966 8.388H6.2976L13.7706 0.915C14.9926 -0.305 16.9796 -0.305 18.2016 0.915ZM13.155 3.651L7.3576 9.449C7.0986 9.708 6.9516 10.052 6.9426 10.417L6.8676 13.428H9.8446C10.2246 13.428 10.5806 13.281 10.8496 13.012L16.682 7.178L13.155 3.651ZM14.8306 1.976L14.215 2.59L17.742 6.118L18.3586 5.503C18.6666 5.195 18.8366 4.785 18.8366 4.349C18.8366 3.912 18.6666 3.501 18.3586 3.193L17.1416 1.976C16.5046 1.341 15.4686 1.341 14.8306 1.976Z" fill="black"/>
                                    </svg>
                                </button>

                                {errors.selectedFile && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.selectedFile}</p>}
                            </div>
                        </div>

                        <div className="grid gap-6 lg:grid-cols-2 lg:gap-8 mt-[1.5rem]">
                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='full-name' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>نام و نام‌خانوادگی</p>

                                    {errors.fullName && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.fullName}</p>}
                                </label>

                                <input id='full-name' defaultValue={user?.profile?.data?.name} onChange={(e) => setFullName(e.target.value)} type='text' className='w-full h-[3rem] p-[1rem] bg-[#F5F5F5] input-hover border border-[#F5F5F5] rounded-[0.5rem]' />
                            </div>

                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='phone-number' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>شماره تماس</p>

                                    <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                    {errors.phone && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.phone}</p>}
                                </label>

                                <input id='phone-number' defaultValue={user?.profile?.data?.phone} disabled onChange={(e) => setPhone(e.target.value)} type='text' className='input-hover w-full h-[3rem] p-[1rem] bg-[#F5F5F5] border border-[#F5F5F5] rounded-[0.5rem]' />
                            </div>
                        </div>

                        <div className="grid gap-6 lg:grid-cols-2 lg:gap-8 mt-[1.5rem]">
                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='email' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>آدرس ایمیل</p>

                                    {errors.email && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.email}</p>}
                                </label>

                                <input id='email' defaultValue={user?.profile?.data?.email} onChange={(e) => setEmail(e.target.value)} type='text' className='input-hover w-full h-[3rem] p-[1rem] bg-[#F5F5F5] border border-[#F5F5F5] rounded-[0.5rem]' />
                            </div>

                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='saz' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>ساز</p>

                                    <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                    {errors.saz && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.saz}</p>}
                                </label>

                                <div className='w-full'>
                                {optionsSaz.length > 0 && (
                                    <Select
                                        options={optionsSaz}
                                        onChange={handleSazChange}
                                        placeholder="انتخاب ساز"
                                        styles={customStyles}
                                        defaultValue={saz}
                                    />
                                )}
                            </div>
                            </div>
                        </div>

                        <div className='lg:w-max'>
                            <button disabled={loading} className='btn-hover w-full flex justify-center items-center mt-[2rem] bg-green py-[0.7rem] px-[4rem] rounded-[0.5rem] text-white'>
                                <p>{loading ? 'صبر کنید ...' : 'ذخیره تغییرات'}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default Dashboard;