import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Advertising = ({data}) => {
    return (
        <>
            <section className='my-[2rem]'>
                <div className='px-[2rem] mb-[1rem]'>
                    <h4 className='text-[28px] font-[700] text-black'>تبلیغات</h4>
                </div>

                <div>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation,Autoplay]} 
                        className="slider-shop"
                        navigation={true}
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={32}
                        slidesOffsetBefore={32}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        speed={500}
                    >
                        {data && Array.isArray(data) ? (
                            data.map((data,index) => (
                                <SwiperSlide key={index} className="!w-[20rem] lg:!w-[32rem]">
                                    <div className='bg-[#F9F9F9] border border-[#E1E1E1] p-[1rem] rounded-[0.5rem]'>
                                        <Link to={data?.contact_link}>
                                            <div className='flex justify-center items-center'>
                                                <div className='w-full'>
                                                    <p className='text-[#089584] text-[20px] font-[600] mb-[0.5rem]'>{data?.title}</p>

                                                    <p className='text-[14px] text-[#212121]'>{data?.blurb}</p>

                                                    <div className='hidden lg:flex justify-start items-center mt-[0.5rem]'>
                                                        <div className='flex justify-center items-center'>
                                                            <p dir='ltr' className='ml-[0.3rem] text-[#212121] text-[14px]'>{data?.contact}</p>
                                                            
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.30951 13.5514L11.0569 10.6391C11.2377 10.3377 11.6037 10.2023 11.9372 10.3135C13.2117 10.7383 14.4817 10.95 15.75 10.95C16.1642 10.95 16.5 11.2858 16.5 11.7V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5C7.23579 16.5 1.5 10.7642 1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H6.3C6.71421 1.5 7.05 1.83579 7.05 2.25C7.05 3.51828 7.26167 4.7883 7.68651 6.06283C7.79767 6.39631 7.6623 6.76226 7.36087 6.94312L4.44859 8.69049C5.53397 10.8058 7.19421 12.466 9.30951 13.5514ZM15 14.9836V12.4282C14.012 12.3708 13.0251 12.1998 12.0401 11.9159L10.696 14.1561C11.9998 14.6319 13.4418 14.9151 15 14.9836ZM5.5718 3C5.62925 3.988 5.80017 4.97494 6.08408 5.95991L3.84389 7.30402C3.36806 6.0002 3.08495 4.55825 3.01636 3H5.5718Z" fill="#DB4444"/>
                                                            </svg>
                                                        </div>
                                                        
                                                        <div className='flex justify-center items-center mr-[0.5rem]'>
                                                            <p dir='ltr' className='ml-[0.3rem] text-[#212121] text-[14px]'>{data?.instagram}</p>
                                                            
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 7.36205C9.44245 7.36205 7.36181 9.4427 7.36181 12.0002C7.36181 14.5576 9.44245 16.6382 12 16.6382C14.5575 16.6382 16.6382 14.5576 16.6382 12.0002C16.6382 9.4427 14.5575 7.36205 12 7.36205ZM8.95886 12.0001C8.95886 13.6769 10.3232 15.041 12 15.041C13.6769 15.041 15.0411 13.6769 15.0411 12.0001C15.0411 10.3232 13.6769 8.959 12 8.959C10.3231 8.959 8.95886 10.3232 8.95886 12.0001Z" fill="#DB4444"/>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.96717 3H16.0326C18.7717 3 21.0002 5.22843 21 7.96738V16.0327C21 18.7717 18.7717 21 16.0326 21H7.96717C5.22832 21 3 18.7718 3 16.0327V7.96738C3 5.22843 5.22832 3 7.96717 3ZM16.0327 19.4029C17.8912 19.4029 19.4032 17.8912 19.4032 16.0327L19.4029 7.96738C19.4029 6.10904 17.8911 4.59706 16.0326 4.59706H7.96717C6.10894 4.59706 4.59706 6.10904 4.59706 7.96738V16.0327C4.59706 17.8912 6.10894 19.4032 7.96717 19.4029H16.0327Z" fill="#DB4444"/>
                                                            <path d="M16.8327 6.00788C16.525 6.00788 16.2227 6.13245 16.0054 6.35071C15.787 6.56791 15.6615 6.87029 15.6615 7.17905C15.6615 7.48686 15.7871 7.78913 16.0054 8.00739C16.2227 8.22459 16.525 8.35022 16.8327 8.35022C17.1414 8.35022 17.4428 8.22459 17.661 8.00739C17.8793 7.78913 18.0039 7.48675 18.0039 7.17905C18.0039 6.87029 17.8793 6.56791 17.661 6.35071C17.4438 6.13245 17.1414 6.00788 16.8327 6.00788Z" fill="#DB4444"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='w-[4.5rem] min-w-[4.5rem] lg:w-[9rem] lg:min-w-[9rem]'>
                                                    <img className='w-full rounded-[0.5rem]' src={data?.image} />
                                                </div>
                                            </div>

                                            <div className='flex lg:hidden justify-start items-center mt-[0.5rem]'>
                                                <div className='flex justify-center items-center'>
                                                    <p dir='ltr' className='ml-[0.3rem] text-[#212121] text-[14px]'>{data?.contact}</p>
                                                    
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.30951 13.5514L11.0569 10.6391C11.2377 10.3377 11.6037 10.2023 11.9372 10.3135C13.2117 10.7383 14.4817 10.95 15.75 10.95C16.1642 10.95 16.5 11.2858 16.5 11.7V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5C7.23579 16.5 1.5 10.7642 1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H6.3C6.71421 1.5 7.05 1.83579 7.05 2.25C7.05 3.51828 7.26167 4.7883 7.68651 6.06283C7.79767 6.39631 7.6623 6.76226 7.36087 6.94312L4.44859 8.69049C5.53397 10.8058 7.19421 12.466 9.30951 13.5514ZM15 14.9836V12.4282C14.012 12.3708 13.0251 12.1998 12.0401 11.9159L10.696 14.1561C11.9998 14.6319 13.4418 14.9151 15 14.9836ZM5.5718 3C5.62925 3.988 5.80017 4.97494 6.08408 5.95991L3.84389 7.30402C3.36806 6.0002 3.08495 4.55825 3.01636 3H5.5718Z" fill="#DB4444"/>
                                                    </svg>
                                                </div>
                                                
                                                <div className='flex justify-center items-center mr-[0.5rem]'>
                                                    <p dir='ltr' className='ml-[0.3rem] text-[#212121] text-[14px]'>{data?.instagram}</p>
                                                    
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 7.36205C9.44245 7.36205 7.36181 9.4427 7.36181 12.0002C7.36181 14.5576 9.44245 16.6382 12 16.6382C14.5575 16.6382 16.6382 14.5576 16.6382 12.0002C16.6382 9.4427 14.5575 7.36205 12 7.36205ZM8.95886 12.0001C8.95886 13.6769 10.3232 15.041 12 15.041C13.6769 15.041 15.0411 13.6769 15.0411 12.0001C15.0411 10.3232 13.6769 8.959 12 8.959C10.3231 8.959 8.95886 10.3232 8.95886 12.0001Z" fill="#DB4444"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.96717 3H16.0326C18.7717 3 21.0002 5.22843 21 7.96738V16.0327C21 18.7717 18.7717 21 16.0326 21H7.96717C5.22832 21 3 18.7718 3 16.0327V7.96738C3 5.22843 5.22832 3 7.96717 3ZM16.0327 19.4029C17.8912 19.4029 19.4032 17.8912 19.4032 16.0327L19.4029 7.96738C19.4029 6.10904 17.8911 4.59706 16.0326 4.59706H7.96717C6.10894 4.59706 4.59706 6.10904 4.59706 7.96738V16.0327C4.59706 17.8912 6.10894 19.4032 7.96717 19.4029H16.0327Z" fill="#DB4444"/>
                                                    <path d="M16.8327 6.00788C16.525 6.00788 16.2227 6.13245 16.0054 6.35071C15.787 6.56791 15.6615 6.87029 15.6615 7.17905C15.6615 7.48686 15.7871 7.78913 16.0054 8.00739C16.2227 8.22459 16.525 8.35022 16.8327 8.35022C17.1414 8.35022 17.4428 8.22459 17.661 8.00739C17.8793 7.78913 18.0039 7.48675 18.0039 7.17905C18.0039 6.87029 17.8793 6.56791 17.661 6.35071C17.4438 6.13245 17.1414 6.00788 16.8327 6.00788Z" fill="#DB4444"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </Swiper>
                </div>
            </section>
        </>
    );
};

export default Advertising;