import { useState, useEffect } from "react";
import moment from "moment-jalaali";

const useJalaliDate = (gregorianDate) => {
    const [jalaliDate, setJalaliDate] = useState("");

    useEffect(() => {
        if (gregorianDate) {
            moment.locale("fa");
            moment.loadPersian({ usePersianDigits: true });
            const formattedDate = moment(gregorianDate).format("jD  jMMMM jYYYY");
            setJalaliDate(formattedDate);
        }
    }, [gregorianDate]);

    return jalaliDate;
};

export default useJalaliDate;