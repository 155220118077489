import React, { useState } from 'react';
import ContactUsItem from '../../Components/Leyouts/ContactUs/ContactUsItem';
import whatsappIcon from './../../Assets/Images/ContactUs/WhatsApp.png';
import youtubeIcon from './../../Assets/Images/ContactUs/Youtube.png';
import mailIcon from './../../Assets/Images/ContactUs/Mail.png';
import telegramIcon from './../../Assets/Images/ContactUs/Telegram.png';
import instagramIcon from './../../Assets/Images/ContactUs/Instagram.png';
import linkedINIcon from './../../Assets/Images/ContactUs/LinkedIN.png';
import { Toaster,toast } from 'react-hot-toast';

const ContactUs = () => {
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [loding, setLoding] = useState(false);

    const validateForm = () => {
        let formErrors = {};
        if (!fullName.trim()) formErrors.fullName = 'نام و نام‌خانوادگی اجباری است';
        if (!phoneNumber.trim()) formErrors.phoneNumber = 'شماره تماس اجباری است';
        else if (!/^\d+$/.test(phoneNumber)) formErrors.phoneNumber = 'شماره تماس باید معتبر باشد';
        if (!message.trim()) formErrors.message = 'متن پیام اجباری است';
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoding(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("name", fullName);
            formdata.append("phone", phoneNumber);
            formdata.append("body", message);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch("https://backend.neireez.com/v1/support", requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if(status != 200){
                    let formErrors = {};

                    if(result?.errors?.body)
                    {
                        formErrors.message = result?.errors?.body;
                    }

                    if(result?.errors?.phone)
                    {
                        formErrors.phoneNumber = result?.errors?.phone;
                    }

                    if(result?.errors?.name)
                    {
                        formErrors.fullName = result?.errors?.name;
                    }

                    setErrors(formErrors);
                }
                
                if(status == 201){
                    setFullName('');
                    setPhoneNumber('');
                    setMessage('');

                    toast.success(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
                setLoding(false);
            })
            .catch((error) => console.error(error));
        }
    };

    const whatsappGroup = {
        icon : whatsappIcon,
        title : 'گروه واتس‌اپ',
        btnText : 'عضویت' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی تبادل نظرات با سایر افراد',
        link : 'https://chat.whatsapp.com/HLzrhvP2FRS1QrSGfFxIy7'
    }

    const whatsapp = {
        icon : whatsappIcon,
        title : 'پیام واتس‌اپ',
        btnText : 'ارسال پیام' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'https://wa.me/qr/HV5YT7BECNNVN1'
    }

    const instagram = {
        icon : instagramIcon,
        title : 'صفحه‌ی اینستاگرام',
        btnText : 'مشاهده' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'https://www.instagram.com/neireez.app/'
    }

    const telegram = {
        icon : telegramIcon,
        title : 'تلگرام',
        btnText : 'ارسال پیام' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'https://t.me/neireezapp'
    }

    const mail = {
        icon : mailIcon,
        title : 'ایمیل',
        btnText : 'ارسال ایمیل' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'mailto:neireezapplication@gmail.com'
    }

    const linkdin = {
        icon : linkedINIcon,
        title : 'صفحه‌ی لینکدین',
        btnText : 'مشاهده' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'https://www.linkedin.com/company/neireez-music-application/'
    }

    const youtube = {
        icon : youtubeIcon,
        title : 'کانال یوتیوب',
        btnText : 'مشاهده' ,
        description : 'تبادل نظرات با سایر افراد و اطلاع از آخرین اخبار دنیای موسیقی ',
        link : 'https://youtube.com/@neireez?si=3UkNmPo-VhRrSwK4'
    }

    return (
        <>
            <main className='px-[2rem] lg:px-[4rem] py-[2rem]'>
                <section>
                    <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>تماس با ما</h3>
                </section>

                <section className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 my-[2rem]'>
                    <ContactUsItem data={whatsappGroup} />
        
                    <ContactUsItem data={instagram} />

                    <ContactUsItem data={whatsapp} />

                    <ContactUsItem data={telegram} />

                    <ContactUsItem data={mail} />

                    <ContactUsItem data={linkdin} />

                    <ContactUsItem data={youtube} />
                </section>

                <section className='mb-[1rem]'>
                    <h5 className='text-[#0C0C0C] text-[22px] font-[600]'>فرم تماس برای همکاری</h5>
                </section>

                <section>
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-6 lg:grid-cols-2 lg:gap-8 mb-[1rem]'>
                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='full-name' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>نام و نام‌خانوادگی</p>

                                    <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                    {errors.fullName && <p className='text-red-500 text-sm pr-[0.5rem]'>{errors.fullName}</p>}
                                </label>

                                <input id='full-name' value={fullName} onChange={(e) => setFullName(e.target.value)} type='text' className={`${(errors.fullName) ? '!border-red-500' : ''} w-full h-[3rem] p-[1rem] bg-[#F5F5F5] input-hover border border-[#F5F5F5] rounded-[0.5rem]`} />
                            </div>

                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='phone-number' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>شماره تماس</p>

                                    <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                    {errors.phoneNumber && <p className='text-red-500 text-sm pr-[0.5rem]'>{errors.phoneNumber}</p>}
                                </label>

                                <input id='phone-number' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type='text' className={`${(errors.phoneNumber) ? '!border-red-500' : ''} w-full h-[3rem] p-[1rem] bg-[#F5F5F5] input-hover border border-[#F5F5F5] rounded-[0.5rem]`} />
                            </div>
                        </div>

                        <div className='flex justify-center items-start flex-col'>
                            <label htmlFor='message' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                <p>متن پیام</p>

                                <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                {errors.message && <p className='text-red-500 text-sm pr-[0.5rem]'>{errors.message}</p>}
                            </label>

                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} id='message' type='text' className={`${(errors.message) ? '!border-[#BF4E11]' : ''} input-hover w-full min-h-[8rem] p-[1rem] bg-[#F5F5F5] border border-[#F5F5F5] rounded-[0.5rem]`} ></textarea>
                        </div>

                        <div className='flex justify-center items-center w-full mt-[2rem]'>
                            <button type='submit' className='btn-hover w-full lg:w-[20rem] h-[3rem] rounded-[0.5rem] bg-[#089584] text-white'>
                                {!loding ? 'ارسال' : 'صبر کنید ...'}
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    );
};

export default ContactUs;