import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import Premium from '../../Components/Home/Premium';
import Loading from '../../Components/Leyouts/Home/Loading';

const fetchTools = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/tools`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchToolDetails = async (id) => {
    if (!id) return null;
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/tools/${id}`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات ابزار");
        return null;
    }
    return response.json();
};

const Tools = () => {
    const [toolsId, setToolsId] = useState();
    const { data : tools, error, isLoading } = useQuery(['faq'], fetchTools);

    const { data: toolDetails, error: toolDetailsError, isLoading: isToolDetailsLoading } = useQuery(
        ['toolDetails', toolsId],
        () => fetchToolDetails(toolsId),
        {
            enabled: !!toolsId,
        }
    );

    useEffect(() => {
        setToolsId(tools?.data?.[0]?.id);
    }, [tools])

    return (
        <>
            {isLoading && (
                <Loading />
            )}

            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h4 className='text-[28px] font-[700]'>ابزار ها</h4>
                </section>

                <section className='lg:px-[4rem] py-[1rem]'>
                    <Swiper
                        className="!p-[0.5rem] !flex !justify-center"
                        slidesPerView={'auto'}
                        grabCursor={true}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        breakpoints={{
                            1024: {
                                initialSlide: 3,
                                centeredSlides: true
                            },
                        }}
                        spaceBetween={32}
                    >
                        {tools?.data && Array.isArray(tools?.data) ? (
                            tools?.data.map((data,index) => (
                                <SwiperSlide onClick={(e) => setToolsId(data?.id)} key={index} className={`!cursor-pointer !w-[7rem] p-[0.5rem] !relative !flex !justify-righ !items-center !flex-col rounded-[1rem] ${(toolsId == data?.id) ? 'active-tools-menu' : ''}`}>
                                    <img className='!w-full' src={data?.image} alt="Slide 1" />

                                    <p className='text-[#0D243F] font-[700]'>{data?.title}</p>
                                </SwiperSlide>
                            ))
                        ) : (
                            <></>
                        )}
                    </Swiper>
                </section>

                <section className='px-[2rem] pt-[2rem] pb-[1rem] border-t-[4px] border-[#F4F3F2] flex justify-center items-center'>
                    {!isToolDetailsLoading ? (
                        <div className='text-justify line-hight-blog lg:w-[90%]' dangerouslySetInnerHTML={{ __html : toolDetails?.data?.body }}></div>
                    ) : (
                        <div className='text-[14px] mt-[0.5rem] font-[700] text-center'>
                            <p>صبر کنید ...</p>
                        </div>
                    )}
                </section>

                <Premium />
            </main>
        </>
    );
};

export default Tools;