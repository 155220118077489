import React from 'react';
import AbousUsImg from './../../Assets/Images/AboutUs/AboutUs.svg';
import VectorRight from './../../Assets/Images/AboutUs/Vector-right.png';
import VectorLeft from './../../Assets/Images/AboutUs/Vector-left.png';
import TeamNirizBox from '../../Components/Leyouts/AboutUs/TeamNeireezBox';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';

const fetchAboutUs = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/about-us`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchAboutUsTeam = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/team`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const AboutUs = () => {
    const results = useQueries([
        { queryKey: ['aboutUs'], queryFn: fetchAboutUs },
        { queryKey: ['team'], queryFn: fetchAboutUsTeam }
    ]);

    const [data, team] = results;

    const isLoading = data.isLoading || team.isLoading;
    const isError = data.isError || team.isError;
    

    return (
        <>
            {isLoading && (
                <Loading />
            )}

            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>درباره ما</h3>
                </section>

                <section className='flex justify-between items-center flex-col-reverse lg:flex-row lg:bg-[#EEF8F6] my-[3rem] mt-0 lg:mt-[4rem]'>
                    {data?.data && (
                        <div className='px-[2rem] py-[2rem] lg:w-[50%] lg:pr-[4rem]' dangerouslySetInnerHTML={{ __html : data?.data['about-us'] }}></div>
                    )}

                    <div className='lg:w-[50%] py-[0.5rem] lg:mb-0 px-[2rem] lg:px-[5rem]'>
                        {data?.data && (
                            <img className='w-full h-full' src={data?.data['about-us-image'] } />
                        )}
                    </div>
                </section>

                <section className='relative flex justify-center items-center my-[3rem] min-h-[14rem] overflow-hidden'>
                    <div className='relative z-[1] w-[75vw] grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4'>
                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] font-[800]'>{ data?.data['annual-sales'] }K</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>فروش سالیانه در وب‌سایت</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] font-[800]'>{ data?.data['customers'] }K</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>مشتری فعال در وب‌سایت</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] font-[800]'>{ data?.data['monthly-sales'] }K</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>فروش محصول ماهانه</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] font-[800]'>{ data?.data['vendors'] }K</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>فروشنده فعال در وب‌سایت</p>
                        </div>
                    </div>

                        <img className='absolute left-0 z-0' src={VectorLeft} />

                        <img className='absolute right-0 z-0' src={VectorRight} />
                </section>

                <section className='bg-[#EEF8F6] lg:m-[2rem] lg:rounded-[0.5rem] py-[2rem] px-[1rem] flex justify-center items-center flex-col'>
                    <div className='text-[#0C0C0C] text-[30px] font-[700] text-center'>
                        <p>تیم نیریــز</p>
                    </div>

                    <div className='lg:w-[60%] flex flex-wrap justify-center items-center mt-[1.5rem]'>
                        {team?.data?.data && Array.isArray(team?.data?.data) ? (
                            team?.data?.data.map((data,index) => (
                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={data} />
                                </div>
                            ))
                        ) : (
                            <p>محتوایی برای نمایش یافت نشد</p>
                        )}
                    </div>
                </section>

                <section className='my-[5rem] flex justify-center items-center flex-col lg:flex-row'>
                    <div className='w-full flex justify-center items-center lg:flex-col lg:w-[12rem] px-[1rem] my-[1rem] lg:p-0 lg:my-0 lg:mx-[2rem]'>
                        <div className='min-w-[5rem] w-[5rem] h-[5rem] rounded-full bg-[#EEF8F6] flex justify-center items-center'>
                            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.9506 4.07695L9.95056 8.07695C9.09195 8.32227 8.5 9.10704 8.5 10L8.5 25.8755C8.5 31.6172 11.5766 36.9187 16.5618 39.7674L23.5077 43.7365C24.1226 44.0878 24.8774 44.0878 25.4923 43.7365L32.4382 39.7674C37.4234 36.9187 40.5 31.6172 40.5 25.8755V10C40.5 9.10704 39.908 8.32227 39.0494 8.07695L25.0494 4.07695C24.6903 3.97435 24.3097 3.97435 23.9506 4.07695ZM12.5 25.8755L12.5 11.5086L24.5 8.08003L36.5 11.5086V25.8755C36.5 30.1818 34.1926 34.1579 30.4537 36.2944L24.5 39.6965L18.5463 36.2944C14.8074 34.1579 12.5 30.1818 12.5 25.8755Z" fill="#089584"/>
                            <path d="M22.9142 24.1716L30.5 16.5858L33.3284 19.4142L24.3284 28.4142C23.5474 29.1953 22.281 29.1953 21.5 28.4142L16.5 23.4142L19.3284 20.5858L22.9142 24.1716Z" fill="#089584"/>
                            </svg>
                        </div>

                        <div className='flex justify-center items-start flex-col w-full mr-[1rem]'>
                            <div className='text-[#0C0C0C] text-[22px] font-[600] lg:mt-[1rem] text-center'>
                                <p>گارانتی بازگشت وجه</p>
                            </div>

                            <div  className='text-[#4E4E4E] text-[16px] font-[400] text-center mt-[0.5rem]'>
                                <p>گارانتی اصالت محصول و بازگشت کالا</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex justify-center items-center lg:flex-col lg:w-[12rem] px-[1rem] my-[1rem] lg:p-0 lg:my-0 lg:mx-[2rem]'>
                        <div className='min-w-[5rem] w-[5rem] h-[5rem] rounded-full bg-[#EEF8F6] flex justify-center items-center'>
                            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.6654 16.7631C13.6161 11.086 18.5528 6.75977 24.5 6.75977C30.4472 6.75977 35.3839 11.086 36.3346 16.7631C34.2022 16.8498 32.5 18.606 32.5 20.7598V30.7598C32.5 32.9022 34.1843 34.6512 36.301 34.7549C35.6389 38.025 33.3812 40.7163 30.3756 41.981C30.4572 41.5867 30.5 41.1782 30.5 40.7598C30.5 37.4461 27.8137 34.7598 24.5 34.7598C21.1863 34.7598 18.5 37.4461 18.5 40.7598C18.5 44.0735 21.1863 46.7598 24.5 46.7598H26.5C33.7197 46.7598 39.663 41.2948 40.4189 34.2759C43.9568 33.0636 46.5 29.7087 46.5 25.7598C46.5 21.8148 43.9618 18.4627 40.4294 17.2473C39.6674 9.12007 32.8265 2.75977 24.5 2.75977C16.1735 2.75977 9.3326 9.12006 8.57056 17.2473C5.03817 18.4626 2.5 21.8148 2.5 25.7598C2.5 30.7303 6.52944 34.7598 11.5 34.7598H12.5C14.7091 34.7598 16.5 32.9689 16.5 30.7598L16.5 20.7598C16.5 18.606 14.7978 16.8498 12.6654 16.7631ZM24.5 42.7598C25.6046 42.7598 26.5 41.8643 26.5 40.7598C26.5 39.6552 25.6046 38.7598 24.5 38.7598C23.3954 38.7598 22.5 39.6552 22.5 40.7598C22.5 41.8643 23.3954 42.7598 24.5 42.7598ZM8.5 21.7594C7.28555 22.6716 6.5 24.1239 6.5 25.7598C6.5 27.3956 7.28555 28.8479 8.5 29.7601C9.33566 30.3878 10.3744 30.7598 11.5 30.7598H12.5L12.5 20.7598H11.5C10.3744 20.7598 9.33566 21.1317 8.5 21.7594ZM40.5 29.7601C41.7144 28.8479 42.5 27.3956 42.5 25.7598C42.5 24.1239 41.7144 22.6716 40.5 21.7594C39.6643 21.1317 38.6256 20.7598 37.5 20.7598H36.5V30.7598H37.5C38.6256 30.7598 39.6643 30.3878 40.5 29.7601Z" fill="#089584"/>
                            </svg>
                        </div>

                        <div className='flex justify-center items-start flex-col w-full mr-[1rem]'>
                            <div className='text-[#0C0C0C] text-[22px] font-[600] lg:mt-[1rem] text-center'>
                                <p>پشتیبانی ۲۴ ساعته</p>
                            </div>

                            <div  className='text-[#4E4E4E] text-[16px] font-[400] text-center mt-[0.5rem]'>
                                <p>پشتیبانی ۲۴ ساعته در هفت روز هفته</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex justify-center items-center lg:flex-col lg:w-[12rem] px-[1rem] my-[1rem] lg:p-0 lg:my-0 lg:mx-[2rem]'>
                        <div className='min-w-[5rem] w-[5rem] h-[5rem] rounded-full bg-[#EEF8F6] flex justify-center items-center'>
                            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_700_12372)">
                            <path d="M14.5 38.7598C16.7091 38.7598 18.5 36.9689 18.5 34.7598C18.5 32.5506 16.7091 30.7598 14.5 30.7598C12.2909 30.7598 10.5 32.5506 10.5 34.7598C10.5 36.9689 12.2909 38.7598 14.5 38.7598Z" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M34.5 38.7598C36.7091 38.7598 38.5 36.9689 38.5 34.7598C38.5 32.5506 36.7091 30.7598 34.5 30.7598C32.2909 30.7598 30.5 32.5506 30.5 34.7598C30.5 36.9689 32.2909 38.7598 34.5 38.7598Z" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.5 34.7598H8.5C7.39543 34.7598 6.5 33.8643 6.5 32.7598V26.7598M4.5 10.7598H24.5C25.6046 10.7598 26.5 11.6552 26.5 12.7598V34.7598M18.5 34.7598H30.5M38.5 34.7598H40.5C41.6046 34.7598 42.5 33.8643 42.5 32.7598V22.7598M42.5 22.7598H26.5M42.5 22.7598L37.0826 13.7308C36.7212 13.1284 36.0701 12.7598 35.3676 12.7598H26.5" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.0996 34.3594H8.09961C6.99504 34.3594 6.09961 33.4639 6.09961 32.3594V26.3594M4.09961 10.3594H24.0996C25.2042 10.3594 26.0996 11.2548 26.0996 12.3594V34.3594M18.4996 34.3594H30.0996M38.8996 34.3594H40.0996C41.2042 34.3594 42.0996 33.4639 42.0996 32.3594V22.3594M42.0996 22.3594H26.0996M42.0996 22.3594L36.6822 13.3304C36.3208 12.728 35.6698 12.3594 34.9672 12.3594H26.0996" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.5 14.9414H14.5" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.68164 19.3057H10.6816" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.5 23.6689H14.5" stroke="#089584" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_700_12372">
                            <rect width="48" height="48" fill="white" transform="translate(0.5 0.759766)"/>
                            </clipPath>
                            </defs>
                            </svg>
                        </div>

                        <div className='flex justify-center items-start flex-col w-full mr-[1rem]'>
                            <div className='text-[#0C0C0C] text-[22px] font-[600] lg:mt-[1rem] text-center'>
                                <p>ارسال سریع و رایگان</p>
                            </div>

                            <div  className='text-[#4E4E4E] text-[16px] font-[400] text-center mt-[0.5rem]'>
                                <p>ارسال رایگان ماهانه فقط با ۱۴۰ هزار تومان</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default AboutUs;