import React from 'react';

const DetailsFirst = ({data}) => {
    return (
        <>
            <section className='flex justify-between items-center flex-col lg:flex-row lg:bg-[#F4F3F2] my-[3rem] mt-[6rem]'>
                <div className='lg:w-[50%] px-[2rem] lg:px-[5rem]'>
                    <img className='w-full mt-[-2rem] rounded-[1rem]' src={data?.items} />
                </div>

                <div className='px-[2rem] mt-[1rem] lg:w-[50%] lg:pl-[4rem]'>
                    <h5 className='text-[#0C0C0C] text-[18px] lg:text-[28px] font-[800] mb-[0.5rem] lg:mb-[2rem]'>{data?.title}</h5>

                    <h4 className='text-justify'>{data?.description}</h4>
                </div>
            </section>
        </>
    );
};

export default DetailsFirst;