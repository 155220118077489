import React, { useEffect, useState } from 'react';
import Address from '../../Components/Product/Address';
import Price from '../../Components/Product/Price';
import RelatedSlider from '../../Components/Product/RelatedSlider';
import Gallery from '../../Components/Product/Gallery';
import Info from '../../Components/Product/Info';
import { QueryClient, QueryClientProvider, useInfiniteQuery,useQuery } from 'react-query';
import { useLocation, Link,useParams,useNavigate } from 'react-router-dom';
import Loading from '../../Components/Leyouts/Home/Loading';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

const fetchData = async (search = "",token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/products/${search}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};


const fetchDataRelated = async (category,ignor) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/products?categories[0]=${category}&ignore[0]=${ignor}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const Product = () => {
    const selectedColor = useSelector((state) => state.product.selectedColor);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const [search, setSearch] = useState(id);
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    useEffect(() => {
        const newSearch = queryParams.get('search') || id;
        setSearch(newSearch);
    }, [location.search, id]);

    const { data: product, isLoading, error, refetch } = useQuery(
        ['data', search, token], 
        () => fetchData(search , token), 
        {
            refetchOnWindowFocus: false,
            onError: () => {
                navigate('/404');
            },
        }
    );

    const { data: productrelated, isLoadingRelated, errorRelated } = useQuery(
        ['data', product?.data?.categories?.[0]?.id, product?.data?.id], 
        () => fetchDataRelated(product?.data?.categories?.[0]?.id, product?.data?.id), 
        {
            refetchOnWindowFocus: false,
            enabled: !!product && !!product.data && !!product.data.categories && product.data.categories.length > 0,
        }
    );

    if(product == undefined && isLoading != true)
    {
        navigate('/404');
    }

    return (
        <>
            {isLoading && (
                <Loading />
            )}

            <main>
                <Address data={product} />

                <section className='my-[2rem] mx-[2rem] lg:mx-[4rem] flex justify-center lg:justify-between items-start flex-col lg:flex-row'>
                    <section className='w-full lg:w-[75%] flex justify-center lg:justify-center items-start flex-col lg:flex-row'>
                        <Gallery data={product?.data} />

                        <Info data={product} />
                    </section>

                    <Price data={product?.data} />
                </section>

                <RelatedSlider data={productrelated?.data} />
            </main>
        </>
    );
};

export default Product;