import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Slider = ({data}) => {
    return (
        <>
            {(data?.data && Array.isArray(data?.data)) && (
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation,Autoplay]} 
                    className="slider-home !p-[2rem] mb-[1rem] lg:my-[1rem]"
                    navigation={true}
                    loop={true}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    grabCursor={true}
                    observer={true}
                    observeParents={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={0}
                >
                    {data?.data && Array.isArray(data?.data) ? (
                        data?.data.map((data,index) => (
                            <SwiperSlide key={index} className="!w-[80%] lg:!w-[60%]">
                                <Link to={'/shop'} className='!relative !flex !justify-righ !items-center'>
                                    <img className='img-banner-selider-home w-full h-full object-fill' src={data?.banner} alt="Slide 1" />
    
                                    <div className='absolute p-[1rem] lg:p-[6rem]'>
                                        <p className='text-[#FEFEFE] text-[20px] lg:text-[40px] font-[800] lg:mb-[2rem]'>{data?.title}</p>

                                        <Link to={'/shop'} className='hidden lg:flex lg:w-max bg-[#FEFEFE] rounded-[0.5rem] text-[#089584] px-[1.3rem] py-[0.9rem]'>بازدید از فروشگاه</Link>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))
                    ) : (
                        <></>
                    )}
                </Swiper>
            )}
        </>
    );
};

export default Slider;