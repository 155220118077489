import { Navigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import Loading from './../Components/Leyouts/Home/Loading';

const ProtectedRoute = ({ children }) => {
    const { profile, status } = useSelector((state) => state.user);
    const [cookies] = useCookies(['authToken']);
    const location = useLocation();

    if (status === 'loading') {
        return <Loading />;
    }

    if (!cookies.authToken && !profile) {
        localStorage.setItem('redirectPath', location.pathname);
        return <Navigate to="/auth" />;
    }

    return children;
};

export default ProtectedRoute;
