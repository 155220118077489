import React, { useEffect, useState } from 'react';
import img from './../../Assets/Images/Premium/bg-premuim.svg';
import cafebazar from './../../Assets/Images/Home/Google_Play_Store_badge_EN.png';
import googleplay from './../../Assets/Images/Home/get-cafebazaar-en.png';
import appstore from './../../Assets/Images/Home/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-hot-toast';

const Premium = () => {
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;
    const [data, setData] = useState();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const status = searchParams.get('status');
        const invoice = searchParams.get('invoice');
        
        if (status && invoice) {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch(`https://backend.neireez.com/v1/invoices/${invoice}`, requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status === 200 || status === 201) {
                    setData(result);
                }else{
                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }
                }
            })
            .catch((error) => console.error(error));
        }
    }, []);

    return (
        <>
            <main>
                <section className='flex justify-between items-center flex-col-reverse lg:flex-row my-[1rem]'>
                    <div className='lg:w-[50%] p-[1rem] lg:pr-[4rem]'>
                        <div className='flex justify-start items-center mb-[1rem]'>
                            {(data?.data?.status == 'paid') ? (
                                <p className='mr-[0.5rem] text-[#107E18] text-[16px] lg:text-[18px] font-[500] flex justify-start items-center'>
                                    <svg className='ml-[0.5rem]' width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07666 18.167C3.07666 9.88699 9.79666 3.16699 18.0767 3.16699C26.3567 3.16699 33.0767 9.88699 33.0767 18.167C33.0767 26.447 26.3567 33.167 18.0767 33.167C9.79666 33.167 3.07666 26.447 3.07666 18.167ZM15.0767 21.422L24.9617 11.537L27.0767 13.667L15.0767 25.667L9.07666 19.667L11.1917 17.552L15.0767 21.422Z" fill="#028E19"/>
                                    </svg>

                                    <span>پرداخت موفقیت آمیز بود</span>
                                </p>
                            ) : (
                                <p className='mr-[0.5rem] text-[#FF0000] text-[16px] lg:text-[18px] font-[500]'>پرداخت ناموفق بود</p>
                            )}
                        </div>

                        <div className='flex justify-start items-center'>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4103 16.948V4.16699H14.7437V16.9481L10.3531 12.5575L8.46745 14.4431L15.1341 21.1098C15.6548 21.6305 16.499 21.6305 17.0197 21.1098L23.6864 14.4431L21.8008 12.5575L17.4103 16.948Z" fill="#424750"/>
                            <path d="M2.74365 22.8337V24.167C2.74365 26.3761 4.53451 28.167 6.74365 28.167H25.4103C27.6195 28.167 29.4103 26.3761 29.4103 24.167V22.8337H26.7437V24.167C26.7437 24.9034 26.1467 25.5003 25.4103 25.5003H16.077H6.74365C6.00727 25.5003 5.41032 24.9034 5.41032 24.167V22.8337H2.74365Z" fill="#424750"/>
                            </svg>

                            <p className='mr-[0.5rem] text-[#000000] text-[18px] lg:text-[20px] font-[600]'>دانلود اپلیکیشن نیریز</p>
                        </div>

                        <div className='my-[2rem]'>
                            <div className='lg:w-[70%] flex justify-between items-center'>
                                <a href='http://cafebazaar.ir/app/?id=ir.neireez.app&ref=share' className='w-[30%] transition-[0.3s] hover:scale-[1.08]'>
                                    <img className='w-full' src={cafebazar} />
                                </a>
                                
                                <a href='https://apps.apple.com/us/app/neireez-music-application/id1668434011' className='w-[30%] transition-[0.3s] hover:scale-[1.08]'>
                                    <img className='w-full' src={appstore} />
                                </a>
                                
                                <a href='https://play.google.com/store/apps/details?id=ir.neireez.app' className='w-[30%] transition-[0.3s] hover:scale-[1.08]'>
                                    <img className='w-full' src={googleplay} />
                                </a>
                            </div>
                        </div>

                        <div>
                            <Link to={'/'} className='bg-[#EDF0EF] rounded-[0.5rem] px-[1rem] py-[0.5rem]'>بازگشت به نیریز</Link>
                        </div>
                    </div>  

                    <div className='lg:w-[50%] p-[1rem] lg:mr-[1rem]'>
                        <img src={img} />
                    </div>
                </section>
            </main> 
        </>
    );
};

export default Premium;