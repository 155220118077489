import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ProductBox from '../Leyouts/Products/ProductBox';

const DailyAuction = ({data}) => {
    const [timeLeft, setTimeLeft] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const midnight = new Date();
            midnight.setHours(24, 0, 0, 0);

            const difference = midnight - now;

            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({ hours, minutes, seconds });
        };

        calculateTimeLeft();
        const intervalId = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <section className='bg-[#089584] py-[2rem] lg:pr-[2rem] flex justify-center items-center flex-col lg:flex-row my-[2rem]'>
                <div className='w-full lg:w-[15%] lg:min-w-[15%] px-[1rem] lg:px-[2rem] mb-[0.5rem] lg:mb-0 lg:px-0 flex justify-between lg:justify-center items-center lg:flex-col'>
                    <div className='flex justify-center items-center lg:flex-col'>
                        <div>
                            <svg className='!w-[20px] lg:!w-[56px]' width="56" height="61" viewBox="0 0 56 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_378_21922)">
                            <path d="M4.5 10.9776C4.5 7.85158 5.50409 5.32919 7.51227 3.41045C9.54182 1.47015 12.255 0.5 15.6518 0.5C19.0914 0.5 21.8045 1.45937 23.7914 3.37811C25.7995 5.29685 26.8036 7.88392 26.8036 11.1393V13.6294C26.8036 16.7554 25.7995 19.2778 23.7914 21.1965C21.8045 23.0937 19.1127 24.0423 15.7159 24.0423C12.3405 24.0423 9.62727 23.1045 7.57636 21.2289C5.52545 19.3317 4.5 16.7338 4.5 13.4353V10.9776ZM11.9666 13.6294C11.9666 14.8367 12.2977 15.8176 12.96 16.5721C13.6223 17.3267 14.5409 17.704 15.7159 17.704C18.13 17.704 19.337 16.1517 19.337 13.0473V10.9776C19.337 9.77032 19.0166 8.77861 18.3757 8.00249C17.7348 7.22637 16.8268 6.83831 15.6518 6.83831C14.5195 6.83831 13.6223 7.21559 12.96 7.97015C12.2977 8.72471 11.9666 9.74876 11.9666 11.0423V13.6294ZM29.1643 39.4353C29.1643 36.2662 30.1791 33.733 32.2086 31.8358C34.2382 29.9386 36.9407 28.99 40.3161 28.99C43.7343 28.99 46.4475 29.9279 48.4557 31.8035C50.4852 33.6791 51.5 36.2985 51.5 39.6617V42.0871C51.5 45.2347 50.4959 47.757 48.4877 49.6542C46.5009 51.5514 43.7984 52.5 40.3802 52.5C37.0048 52.5 34.2916 51.5622 32.2407 49.6866C30.1898 47.7894 29.1643 45.2023 29.1643 41.9254V39.4353ZM36.5989 42.0871C36.5989 43.1866 36.962 44.1459 37.6884 44.9652C38.4361 45.7844 39.3334 46.194 40.3802 46.194C42.602 46.194 43.8091 45.1269 44.0014 42.9925L44.0334 39.4353C44.0334 38.2065 43.7023 37.2148 43.04 36.4602C42.3777 35.7056 41.4698 35.3284 40.3161 35.3284C39.2266 35.3284 38.34 35.6841 37.6564 36.3955C36.9941 37.0854 36.6416 38.034 36.5989 39.2413V42.0871ZM18.5893 48.0373L13.1736 45.2886L37.6564 5.77115L43.04 8.48756L18.5893 48.0373Z" fill="white"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_378_21922" x="0.5" y="0.5" width="55" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_378_21922"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_378_21922" result="shape"/>
                            </filter>
                            </defs>
                            </svg>
                        </div>

                        <div className='text-[#FFFFFF] text-[16px] lg:text-[24px] font-[700] mr-[0.5rem] lg:mr-0 lg:mt-[0.5rem]'>
                            <p>{data?.title}</p>
                        </div>

                        <div className='mr-[0.5rem] lg:mr-0 lg:mt-[0.5rem] flex justify-center items-center'>
                            <div className='w-[2rem] h-[2rem] min-w-[2rem] bg-[#FFFFFF33] rounded-[4px] flex justify-center items-center'>
                                <p className='text-[#FFFFFF] text-[14px] lg:text-[20px] font-[600]'>{timeLeft.seconds.toString().padStart(2, '0')}</p>
                            </div>

                            <div className='mx-[0.5rem] text-[#FFFFFF] text-[14px] lg:text-[20px] font-[600]'>
                                <p>:</p>
                            </div>

                            <div className='w-[2rem] h-[2rem] min-w-[2rem] bg-[#FFFFFF33] rounded-[4px] flex justify-center items-center'>
                                <p className='text-[#FFFFFF] text-[14px] lg:text-[20px] font-[600]'>{timeLeft.minutes.toString().padStart(2, '0')}</p>
                            </div>

                            <div className='mx-[0.5rem] text-[#FFFFFF] text-[14px] lg:text-[20px] font-[600]'>
                                <p>:</p>
                            </div>

                            <div className='w-[2rem] h-[2rem] min-w-[2rem] bg-[#FFFFFF33] rounded-[4px] flex justify-center items-center'>
                                <p className='text-[#FFFFFF] text-[14px] lg:text-[20px] font-[600]'>{timeLeft.hours.toString().padStart(2, '0')}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button className='lg:mt-[2rem] flex justify-center items-center text-[#FFFFFF] text-[16px] font-[600]'>
                            <p>مشاهده همه</p>

                            <svg className='mr-[0.5rem]' width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.91714 12.5001L7.21004 16.793L5.79582 18.2072L0.795823 13.2072C0.405299 12.8167 0.405299 12.1835 0.795823 11.793L5.79582 6.79297L7.21004 8.20718L2.91714 12.5001Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className='lg:w-[85%] w-full'>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation]} 
                        className="slider-shop"
                        navigation={true}
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        breakpoints={{
                            1024: {
                            slidesOffsetAfter :32,
                            slidesOffsetBefore : 32,
                            },
                        }}
                        speed={500}
                    >
                        {data?.items && Array.isArray(data?.items) ? (
                            data?.items.map((data,index) => (
                                <SwiperSlide className="!w-[12rem]">
                                    <ProductBox data={data} />
                                </SwiperSlide>
                            ))
                        ) : (
                            <></>
                        )}
                    </Swiper>
                </div>
            </section>
        </>
    );
};

export default DailyAuction;