import React, { useState } from 'react';
import Slider from '../../Components/Home/Slider';
import Application from '../../Components/Home/Application';
import Premium from '../../Components/Home/Premium';
import DetailsFirst from '../../Components/Home/DetailsFirst';
import SliderShop from '../../Components/Home/SliderShop';
import DetailsSecend from '../../Components/Home/DetailsSecend';
import Articles from '../../Components/Home/Articles';
import Advertising from '../../Components/Home/Advertising';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import { useCookies } from 'react-cookie';

const fetchProduct = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/products?per_page=10`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchSlider = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/banners`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchPost = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/posts?per_page=10`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};


const fetchADcart = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://api.neireez.com/api/v1/ad_cards`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchDetailsFirst = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/home?slug[4]=banner2&slug[5]=banner3`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const Home = () => {
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    const results = useQueries([
        { 
            queryKey: ['product'], 
            queryFn: () => fetchProduct(token), 
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
        },
        { 
            queryKey: ['post'], 
            queryFn: () => fetchPost(token), 
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
        },
        { 
            queryKey: ['ADcart'], 
            queryFn: () => fetchADcart(token), 
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
        },
        { 
            queryKey: ['slider'], 
            queryFn: () => fetchSlider(token), 
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
        },
        { 
            queryKey: ['DetailsFirstData'], 
            queryFn: () => fetchDetailsFirst(token), 
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
        }
    ]);

    const [product, post, ADcart, slider , DetailsFirstData] = results;

    const isLoading = product.isLoading || post.isLoading || slider.isLoading || ADcart.isLoading || DetailsFirstData.isLoading;
    const isError = product.isError || post.isError || slider.isError || ADcart.isError || DetailsFirstData.isError;
    
    return (
        <>
            {isLoading && (
                <Loading />
            )}

            <main>
                <Slider data={slider?.data} />

                <Application />

                <Premium />

                <DetailsFirst data={DetailsFirstData?.data?.data?.banner2} />

                <SliderShop data={product?.data?.data} />

                <DetailsSecend data={DetailsFirstData?.data?.data?.banner3} />

                <Articles data={post?.data?.data} />

                <Advertising data={ADcart?.data?.data} />
            </main>
        </>
    );
};

export default Home;