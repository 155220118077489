import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Thumbs, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';

const Gallery = ({data}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [thumbsSwiperModal, setThumbsSwiperModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const { profile, status } = useSelector((state) => state.user);
    const [hasWishlist, setHasWishlist] = useState(false);
    const [cookies] = useCookies(['authToken']);
    const selectedColor = useSelector((state) => state.product.selectedColor);
    var token = cookies.authToken;

    useEffect(() => {
        if (selectedColor?.has_wishlist) {
            setHasWishlist(true);
        } else {
            setHasWishlist(false);
        }
    }, [selectedColor]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const AddToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("color_id", selectedColor?.id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/add", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(true);
                toast.success('محصول با موفقیت به لیست علاقه مندی ها اضاف شد .',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }

    const RemoveToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "product_id": id
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/remove", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(false);
                toast.success('محصول با موفقیت از لیست علاقه مندی ها حذف شد .',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }

    return (
        <>
            <section className='w-full lg:w-[40%] mb-[1rem] lg:m-0 relative'>
                {profile && (
                    !hasWishlist ? (
                        <button onClick={(e) => AddToWishlist(data?.default_color?.id)} className='absolute !z-[2] right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 7.80206 1.30058 9.47538 2.65631 10.6073L9.63807 17.0991C10.4057 17.8129 11.5943 17.8129 12.3619 17.099L19.3547 10.5961C20.6855 9.50406 21.5 7.81759 21.5 6C21.5 2.68629 18.8137 0 15.5 0C13.7393 0 12.116 0.765528 11 2.03031C9.88396 0.765528 8.26067 0 6.5 0ZM3.99778 9.12368L3.95388 9.08519C3.03888 8.32899 2.5 7.20961 2.5 6C2.5 3.79086 4.29086 2 6.5 2C8.04795 2 9.43585 2.8867 10.1005 4.25491C10.4652 5.00561 11.5348 5.00561 11.8995 4.25491C12.5642 2.8867 13.9521 2 15.5 2C17.7091 2 19.5 3.79086 19.5 6C19.5 7.21303 18.958 8.33528 18.0386 9.0914L11 15.6345L3.99778 9.12368Z" fill="#666666"/>
                            </svg>
                        </button>
                    ) : (
                        <button onClick={(e) => RemoveToWishlist(data?.id)} className='absolute !z-[2] right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 9C1.5 5.68629 4.18629 3 7.5 3C9.26067 3 10.884 3.76553 12 5.03031C13.116 3.76553 14.7393 3 16.5 3C19.8137 3 22.5 5.68629 22.5 9C22.5 10.8176 21.6855 12.5041 20.3547 13.5961L13.3619 20.099C12.5943 20.8129 11.4057 20.8129 10.6381 20.0991L3.65631 13.6073C2.30058 12.4754 1.5 10.8021 1.5 9Z" fill="#DB4444"/>
                            </svg>
                        </button>
                    )
                )}

                <Swiper
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    modules={[FreeMode, Thumbs, Pagination]}
                    className="slider-main-gallery mb-[0.5rem] !flex justify-center items-center flex-col"
                    grabCursor={true}
                    pagination={true}
                    breakpoints={{
                        1024: {
                            pagination : false
                        },
                    }}
                >
                    <SwiperSlide>
                        <img className='rounded-[0.5rem]' src={data?.image?.original} />
                    </SwiperSlide>

                    {data?.gallery && Array.isArray(data?.gallery) ? (
                        data?.gallery.map((data,index) => (
                            <SwiperSlide>
                                <img className='rounded-[0.5rem]'  src={data?.original} />
                            </SwiperSlide>
                        ))
                    ) : (
                        <p></p>
                    )}
                </Swiper>

                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    grabCursor={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Thumbs]}
                    className="slider-gallery !hidden lg:!block"
                >
                    <SwiperSlide className='cursor-pointer p-[0.25rem] rounded-[0.5rem] border border-[#0000001A]'>
                        <img className='rounded-[0.5rem] w-full h-full' src={data?.image?.original} />
                    </SwiperSlide>

                    {data?.gallery && Array.isArray(data?.gallery) ? (
                        data?.gallery.slice(0, 2).map((data,index) => (
                            <SwiperSlide className='cursor-pointer p-[0.25rem] rounded-[0.5rem] border border-[#0000001A]'>
                                <img className='rounded-[0.5rem]'  src={data?.original} />
                            </SwiperSlide>
                        ))
                    ) : (
                        <p></p>
                    )}

                    {data?.gallery.length === 3 && (
                        <SwiperSlide onClick={openModal} className='p-[0.25rem] cursor-pointer !flex justify-center items-center rounded-[0.5rem] border border-[#0000001A] relative'>
                            <img className='rounded-[0.5rem] w-full h-full blur-sm overflow-hidden brightness-200' src={data?.gallery[2]?.original} />

                            <svg className='absolute z-[1]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 12C1 10.3431 2.34315 9 4 9C5.65685 9 7 10.3431 7 12C7 13.6569 5.65685 15 4 15C2.34315 15 1 13.6569 1 12Z" fill="#424750"/>
                            <path d="M9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z" fill="#424750"/>
                            <path d="M17 12C17 10.3431 18.3431 9 20 9C21.6569 9 23 10.3431 23 12C23 13.6569 21.6569 15 20 15C18.3431 15 17 13.6569 17 12Z" fill="#424750"/>
                            </svg>
                        </SwiperSlide>
                    )}
                </Swiper>
            </section>

            <div className={`fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh] flex items-end lg:items-center justify-center transition-opacity duration-300 ${isModalOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={handleOverlayClick}>
                <div className={`bg-[#121212] relative z-[50010] rounded-[1rem] transform transition-all ${isModalOpen ? 'scale-100' : 'scale-95'}`}>
                    <div className="bg-white p-[2rem]  h-[100vh] w-[100vw] relative hidden lg:block overflow-y-auto">
                        <button onClick={closeModal} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.75 4.75L19.25 19.25M19.25 4.75L4.75 19.25" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                        </button>

                        <div className='flex justify-center items-center'>
                            <Swiper
                                spaceBetween={10}
                                thumbs={{ swiper: thumbsSwiperModal && !thumbsSwiperModal.destroyed ? thumbsSwiperModal : null }}
                                modules={[FreeMode, Thumbs]}
                                className="slider-main-gallery-modal mb-[0.5rem] !w-[30%]"
                                grabCursor={true}
                            >
                                <SwiperSlide>
                                    <img className='rounded-[0.5rem]' src={data?.image?.original} />
                                </SwiperSlide>

                                {data?.gallery && Array.isArray(data?.gallery) ? (
                                    data?.gallery.map((data,index) => (
                                        <SwiperSlide>
                                            <img className='rounded-[0.5rem]'  src={data?.original} />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p></p>
                                )}
                            </Swiper>
                        </div>

                        <div className='flex justify-center items-center mt-[1rem]'>
                            <Swiper
                                onSwiper={setThumbsSwiperModal}
                                spaceBetween={10}
                                slidesPerView={6}
                                freeMode={true}
                                grabCursor={true}
                                watchSlidesProgress={true}
                                navigation={true}
                                modules={[FreeMode, Thumbs, Navigation]}
                                className="slider-gallery-modal !hidden lg:!block !w-[60%]"
                            >
                                <SwiperSlide className='cursor-pointer p-[0.25rem] rounded-[0.5rem] border border-[#0000001A]'>
                                    <img className='rounded-[0.5rem]' src={data?.image?.original} />
                                </SwiperSlide>

                                {data?.gallery && Array.isArray(data?.gallery) ? (
                                    data?.gallery.map((data,index) => (
                                        <SwiperSlide className='cursor-pointer p-[0.25rem] rounded-[0.5rem] border border-[#0000001A]'>
                                            <img className='rounded-[0.5rem] w-full h-full'  src={data?.original} />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p></p>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div onClick={closeModal} className={`hidden lg:block fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh]  bg-black opacity-[0.6] bg-opacity-75 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`} aria-hidden="true"></div>
            </div>
        </>
    );
};

export default Gallery;