import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ProductBox from '../Leyouts/Products/ProductBox';

const SliderShop = ({data}) => {
    return (
        <>
            <section className='my-[2rem] pb-[2rem] bg-[#EEF8F6]'>
                <div className='flex justify-between items-center p-[1rem] lg:p-[2rem]'>
                    <div className='flex justify-center items-center'>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.0002 3C22.6701 3 26.4792 6.18346 26.6764 10.1747L26.6844 10.5H30.1581C31.0488 10.5 31.7829 11.0791 31.8833 11.8251L31.8949 12V28.5C31.8949 30.8965 29.7258 32.8555 26.9906 32.9924L26.6844 33H9.316C6.54108 33 4.27279 31.1266 4.11431 28.7644L4.10547 28.5V12C4.10547 11.2307 4.77596 10.5967 5.63976 10.5101L5.84231 10.5H9.316C9.316 6.35786 13.204 3 18.0002 3ZM28.4213 13.5H26.6844V16.5H23.2107V13.5H12.7897V16.5H9.316V13.5H7.57915V28.5C7.57915 29.2101 8.15046 29.8049 8.91775 29.9604L9.11344 29.9899L9.316 30H26.6844C27.5751 30 28.3092 29.4209 28.4096 28.6749L28.4213 28.5V13.5ZM12.7985 10.2356C12.957 7.87338 15.2253 6 18.0002 6C20.8779 6 23.2107 8.01472 23.2107 10.5H12.7897L12.7985 10.2356Z" fill="#089584"/>
                        </svg>

                        <p className='mr-[0.5rem] text-[18px] lg:text-[28px] font-[800] text-black'>فروشگاه نیریز</p>
                    </div>

                    <Link to={'/shop'} className='flex justify-center items-center text-[#089584] lg:bg-[#089584] lg:px-[1rem] py-[0.5rem] rounded-[0.5rem] lg:text-white'>
                        <p className='ml-[0.5rem]'>خرید از فروشگاه</p>

                        <svg className='hidden lg:block' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="white"/>
                        </svg>

                        <svg className='lg:hidden' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4171 12.3448L14.71 16.6377L13.2958 18.0519L8.29582 13.0519C7.9053 12.6614 7.9053 12.0282 8.29582 11.6377L13.2958 6.6377L14.71 8.05191L10.4171 12.3448Z" fill="#089584"/>
                        </svg>
                    </Link>
                </div>

                <div>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation]} 
                        className="slider-shop"
                        navigation={true}
                        loop={true}
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={32}
                        slidesOffsetBefore={32}
                        speed={500}
                    >
                        {data && Array.isArray(data) ? (
                            data.map((data,index) => (
                                <SwiperSlide key={index} className="!w-[12rem]">
                                    <ProductBox data={data} />
                                </SwiperSlide>
                            ))
                        ) : (
                            <></>
                        )}
                    </Swiper>
                </div>
            </section>
        </>
    );
};

export default SliderShop;