import React from 'react';
import DailyAuction from '../../Components/Shop/DailyAuction';
import Category from '../../Components/Shop/Category';
import Futures from '../../Components/Shop/Futures';
import BestSellers from '../../Components/Shop/BestSellers';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import LastProduct from '../../Components/Shop/LastProduct';
import CategoryList from '../../Components/Shop/CategoryList';
import { useCookies } from 'react-cookie';

const fetchHome = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/home?slug[0]=daily_auction&slug[1]=categories&slug[2]=best_sellers`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchSliderHome = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/banners`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const Shop = () => {
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    const results = useQueries([
        { queryKey: ['home'], queryFn: () => fetchHome(token) },
        { queryKey: ['homeSlider'], queryFn: fetchSliderHome }
    ]);

    const [home, homeSlider] = results;

    const isLoading = home.isLoading || homeSlider.isLoading;
    const isError = home.isError || homeSlider.isError;

    return (
        <>
            {isLoading && (
                <Loading />
            )}

            <main>
                <CategoryList data={home?.data?.data?.categories} />

                <LastProduct />
                
                <Category data={home?.data?.data?.categories} />

                <BestSellers data={home?.data?.data?.best_sellers} />

                <DailyAuction data={home?.data?.data?.daily_auction} />

                <Futures />
            </main>
        </>
    );
};

export default Shop;